/* Basic styles for the app */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px 20px;
  position: relative;
  z-index: 1000;
}

.navbar a {
  color: white;
  padding: 14px 20px;
  text-decoration: none;
  text-align: center;
}

.navbar a:hover {
  background-color: #ddd;
  color: black;
}

/* Hamburger Menu (three lines) styling */
.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 2000;
}

.hamburger-menu div {
  width: 30px;
  height: 4px;
  background-color: #fff;
  margin: 6px 0;
}

/* Style for the navbar links */
.navbar-links {
  display: flex;
  transition: opacity 0.3s ease; /* Add smooth fade effect */
  opacity: 1; /* Links visible by default */
}

/* Hide the navbar links on mobile */
@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px; /* Adjust this according to your navbar height */
    left: 0;
    background-color: #333;
    z-index: 1;
    opacity: 0;
  }

  /* Make the links visible when the menu is open */
  .navbar-links.active {
    display: flex;
    opacity: 1;
  }

  /* Show hamburger menu on mobile */
  .hamburger-menu {
    display: flex;
  }
}

/* Styles for the footer */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
}

footer a {
  color: white;
  text-decoration: none;
}

/* Page-specific styles */
h1 {
  text-align: center;
  margin-top: 20px;
}

/* Adjust the layout of the main content */
.main-content {
  flex: 1;
  padding: 20px;
}

button {
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #555;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .app {
    flex-direction: column;
  }

  .main-content {
    padding: 15px;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
}
