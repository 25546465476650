.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 40px;
}

.footer p {
    margin: 0;
    font-size: 1rem;
}

.footer-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.footer-links a {
    color: #ddd;
    text-decoration: none;
}

.footer-links a:hover {
    color: #fff;
}
