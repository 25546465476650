/* Container styling */
.courses-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.courses-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

/* Grid layout for courses */
.courses-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Individual course card styling */
.course-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s;
}

.course-card:hover {
    transform: translateY(-5px);
}

.course-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.course-card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 8px;
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
    .courses-grid {
        justify-content: space-between;
    }

    .course-card {
        flex: 1 1 45%;
    }
}

@media (min-width: 1024px) {
    .course-card {
        flex: 1 1 30%;
    }
}
